<template>
  <div id="home_container" class="outer-wrapper height-s-fix">
    <Transition name="image-cover">
      <img src="@/assets/images/header.jpg" v-if="isGifLoading && !isMobile" alt="" class="img-fluid m-0 image-cover">
    </Transition>
    <div class="row mx-0 position-absolute">
      <div class="ct-col col-lg-8 p-0 ">
        <div class="col-md-12">
          <Transition name="brand-logo" appear>
            <div class="brand-logo-mob text-left" v-if="!isMobile || (!isGifLoading && isMobile)">
              <img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid">
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div class="row h-100 no-gutters">
      <div class="col-12">
      <div class="h-100 no-gutters row ">
        <div class="col-12 col-lg-4 banner-image image1"></div>
        <div class="col-12 col-lg-4 banner-image image2"></div>
        <div class="col-12 col-lg-4 banner-image image3"></div>
      </div>
    </div>
    <router-link to="/inventory" class="btn btn-proceed" >
      Proceed &nbsp;
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6673 15L10.5007 13.7917L13.459 10.8333H3.33398V9.16667H13.459L10.5007 6.20833L11.6673 5L16.6673 10L11.6673 15Z" fill="#0499B7"/>
      </svg>
    </router-link>
    </div>
  </div>
</template>

<script>
import {
  masterJson,
  userTypeUrl
} from '@/assets/scripts/utility.js';
import {
  FormSteps
} from '@/assets/scripts/utility.js';
export default {
  data() {
    return {
      formSteps: FormSteps,
      prevLabel: "",
      show: false,
      nextLabel: "",
      isDownVisible: true,
      isNewCustomer: true,

    }
  },
  computed: {
    masterBuildings() {
      return masterJson.Buildings;
    },
    slideImages() {
      return masterJson.Buildings.filter(building => building.image && building.status === "Available");
    },
    isLoading() {
      return this.$store.getters.isLoading
    },
    isGifLoading() {
      return this.$store.getters.isGifLoading;
    },
    isMobile() {
      return window && window.innerWidth <= 991;
    }
  },
  watch: {
    isGifLoading(newValue) {
      if (!newValue) {
        this.$refs && this.$refs.carousel && this.$refs.carousel.$forceUpdate();
      }
    }
  },
  methods: {
    toggleNav() {
      $('#navbarSupportedContent').toggle();
    },
    scrollToSection(e) {
      e.preventDefault();
      let targetsection = (e.target.getAttribute("href")).slice(1);
      let el = document.getElementById(targetsection);
      this.$refs[targetsection].scrollIntoView({
        behavior: "smooth"
      })
      $('#navbarSupportedContent').toggle();
    },
    downloadBrocher() {
      window.open('https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/shubham-artesia-brochure.pdf')
    },
    navigate() {
      this.$store.dispatch('setLoading', true);
    },
    getUserType() {
      if (this.$route.query.user) {
        let userId = this.$route.query.user;
        userId = atob(userId);
        this.$axios.get(userTypeUrl + '?user_id=' + userId).then((res) => {
          if (res.data.user_type === 'EndUser') {
            this.isNewCustomer = false;
          }
        });
      }
    }
  },
  created() {
    // let userId = this.$route.query.user_id;
    // if (userId) {
    //   userId = btoa(userId);
    //   this.$router.replace({
    //     query: {
    //       user: userId
    //     }
    //   });
    //   this.$store.dispatch('setUserId', userId);
    //   this.$cookies.set('nr-user-id', userId, '1d');
    // } else if (this.$route.query.user) {
    //   this.$store.dispatch('setUserId', this.$route.query.user);
    // } else {
    //   const storedId = this.$cookies.get('nr-user-id');
    //   if (storedId) {
    //     this.$store.dispatch('setUserId', storedId);
    //   } else {
    //     this.$store.dispatch('setUserId', null);
    //   }
    // }
  },
  mounted() {
    this.getUserType();
    // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    //     anchor.addEventListener('click', function (e) {
    //         e.preventDefault();
    //         $('html, body').animate({
    //             scrollTop: $($.attr(this, 'href')).offset().top - 100 
    //         }, 300);
    //     }, {passive: true});
    // });
    // Initialize default plot selection for next screen
    // let buildings = Object.assign({}, ...this.masterBuildings.filter(f => f.key === 'Plot 67'))
    // this.$store.dispatch("setTypology", buildings);
  }
}
</script>

<style scoped>

.btn-proceed{
  position: absolute;
  left: 50%;
  bottom: 16px;
  z-index: 10;
  background-color: #fff;
  border-radius: 100px;
  padding: 16px 100px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap;
}

.height-s-fix{
  height: 100vh;
  height: 100svh;
  width: 100vw;
}

.banner-image{
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.brand-logo-mob {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1033;
}

.image1{
  background-image: url(../assets/images/image-2.jpg);
  background-position: right bottom;
}

.image2{
  background-image: url(../assets/images/project-main-bg.jpg);
  background-position: center bottom;

}

.image3{
  background-image: url(../assets/images/image-3.jpg);
  background-position: center;
}


.image-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1031;
}

.image-cover-leave-from {
  opacity: 1;
}

.image-cover-leave-active {
  transition: opacity 0.5s ease-out 0.5s;
}

.image-cover-leave-to {
  opacity: 0;
}

@media(max-width: 991px){
  .banner-image{
    height: 33.333333%;
  }
}

</style>
